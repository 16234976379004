import React, { useState, createContext, useContext } from 'react';

const KontulariContext = createContext({});

export function KontulariProvider({ children }) {
  const [user, setUser] = useState({});
  return <KontulariContext.Provider value={{ user, setUser }}>{children}</KontulariContext.Provider>;
}

export function useUser() {
  const context = useContext(KontulariContext);

  return context;
}
