import React from "react"

import "./node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./src/css/global.css"
import "./src/css/argon.css"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "react-toastify/dist/ReactToastify.css"

import "react-circular-progressbar/dist/styles.css"

import { KontulariProvider } from './src/hooks/kont'

export const wrapRootElement = ({ element }) => (
  <KontulariProvider>
      {element}
  </KontulariProvider>
)
